import React, {useRef, useState} from "react";
import Layout from "../components/layout";
import {navigate} from "gatsby";

const Login = () => {

    const [error, setError] = useState(null);
    const [inProgress, setInProgress] = useState(null);

    const passwordRef = useRef();

    const login = () => {
        setError(null);
        setInProgress(true);
        const password = passwordRef.current.value.trim();
        if (password === "") {
            setError("Invalid password!!");
            setInProgress(false);
        }
        fetch('/api/login',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({password})
            })
            .then((response) => {
                if (response.status === 200) {
                    window.sessionStorage.setItem('logged_in', 'true');
                    navigate('/');
                } else {
                    setError("Invalid password!!");
                }
            })
            .finally(() => {
                setInProgress(false);
            });
    }

    return (
        <Layout>
            <div
                className="thank-you-page wrapper w-full lg:w-1/2 pt-8">
                <div className="container" style={{minHeight: 400}}>
                    <div>
                        <div className={"text-red text-sm"}>{error}</div>
                        <input type="password"
                               placeholder="Enter the password"
                               className="form-control
                                              w-full
                                              px-3
                                              py-2
                                              text-gray-700
                                              border-default
                                              rounded
                                              m-0
                                              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                               ref={passwordRef}/>
                    </div>
                    <div>
                        <button type="button"
                                onClick={login}
                                disabled={inProgress}
                                className="my-10 w-1/2 text-white bg-denim hover:bg-blue-800 rounded-lg px-5 py-3">
                            Login
                        </button>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
export default Login;
